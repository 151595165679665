import axios from 'axios'
import { VueAxios } from './axios'
import { message } from 'ant-design-vue'
import router from '@/router'; // 确保引入了正确的路由实例

const baseURL = 'https://xsum.pro/baseurl';
// const baseURL = 'http://10.10.10.10:3000';


// 创建 axios 实例
const request = axios.create({
  baseURL: baseURL, // api base_url
  timeout: 60 * 1000 * 10 // 请求超时时间
})

// const err = (error) => {
//   debugger
//   if (error.response) {
//     const data = error.response.data
//     debugger
//     if (error.response.status === 403) {
//       notification.error({
//         message: 'Forbidden',
//         description: data.message
//       })

//     }
//     if (error.response.status === 401 && !(data.result && data.result.isLogin)) {
//       notification.error({
//         message: 'Unauthorized',
//         description: 'Authorization verification failed'
//       })
//       if (token) {
//         store.dispatch('Logout').then(() => {
//           setTimeout(() => {
//             window.location.reload()
//           }, 1500)
//         })
//       }
//     }
//   }
//   return Promise.reject(error)
// }

// request interceptor
request.interceptors.request.use(config => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers['token'] = token // 让每个请求携带自定义 token 请根据实际情况自行修改
  }
  return config
})

request.interceptors.response.use(
  (response) => {
    // 处理响应数据
    return response;
  },
  (error) => {
    if (error.response) {
      if(error.response.status == 401){
        router.push('/login');
      }
      message.error({ content: error.response.data.message, duration: 2 });
    } else if (error.request) {
      // 请求已发出，但没有收到响应
      message.error({ content: error.request, duration: 2 });
    } else {
      message.error({ content: error.message, duration: 2 });
    }
    return Promise.reject(error);
  }
);

const installer = {
  vm: {},
  install(Vue) {
    Vue.use(VueAxios, request)
  }
}

export default request

export {
  installer as VueAxios,
  // request as axios
  request
}
